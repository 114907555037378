import { MasterPartnerSettingsDao } from "@db-models/partner-settings.model";

export const PARTNER_SETTINGS_TYPE = {
  PARTNER_ATTRIBUTE: 'partnerAttribute',
  PARTNER_SETTING: 'partnerSetting'
} as const;

export const PARTNER_SETTINGS_INPUT_TYPE = {
  TOGGLE: 'toggle',
  SELECT: 'select',
  INPUT: 'input',
  INPUT_GROUP: 'input-group',
  EMAIL: 'email',
  ANGULAREDITOR: 'angularEditor',
  TAGINPUT: 'tagInput',
  DATE: 'date',
  MULTISELECT: 'multiSelect'
} as const;

export const PARTNER_SETTINGS = {
  CUSTOM_EMAIL_SENDER_ADDRESS: 1,
  CUSTOM_SMS_SENDER_NAME: 2,
  LANDINGPAGE_HEADER_BACKGROUND_COLOR: 7,
  LANDINGPAGE_TAB_CONTENT: 11,
  CUSTOM_EMAIL_SENDER_NAME: 14,
  STRIPE_API_KEY: 17,
  IS_ONLINE_PAYMENT_MANDATORY: 18,
  IS_SERVICE_ONLINE_PAYMENT_ENABLED: 19,
  IS_EVENT_ONLINE_PAYMENT_ENABLED: 20,
  WEBHOOK_ENDPOINT: 34,
  WEBHOOK_HEADER_KEY: 35,
  WEBHOOK_HEADER_VALUE: 36,
  SMARTACTION_SATISFACTIONSURVEY_TRIGGER: 48,
  IS_BEXIO_APPOINTMENT_SERVICE_PAYMENT_ENABLED: 49,
  IS_BEXIO_EVENT_PAYMENT_ENABLED: 50,
  BEXIO_DEFAULT_TAX_ID: 51,
  BEXIO_DEFAULT_ACCOUNT_ID: 52,
  BEXIO_DEFAULT_BANK_ACCOUNT_ID: 53,
  BEXIO_ONLY_SYNC_CUSTOMER: 55,
  ONLINE_PAYMENT_SHOW_ON_SITE_PAYMENT_TYPE: 56,
  IS_APPOINTMENT_REBOOKING_DISABLED: 61,
  BEXIO_MWST_TYPE: 62,
  CUSTOM_EMAIL_FOR_CANCELLATIONS: 63,
  DASHBOARD_HIDE_PROGRESS_BAR: 64,
  DASHBOARD_HIDE_UPCOMING_BOOKINGS: 65,
  DASHBOARD_HIDE_STATISTICS: 66,
  DASHBOARD_HIDE_BOOKING_CHART: 67,
  DASHBOARD_HIDE_SHORTCUTS: 68,
  DASHBOARD_HIDE_COMING_SOON: 69,
  DASHBOARD_HIDE_BOOKING_LINK: 70,
  SHOW_STORES_AS_DROPDOWN: 72,
  ONLY_CREATE_APPOINTMENT_WHEN_EVENT_REGISTRATION_EXISTS: 74,
  BEXIO_MWST_IS_NET: 75,
  BEXIO_CONTACT_PERSON_ID: 76,
  OFFER_DELETE_CUSTOMER_DATA_OPTION: 86,
  ENABLE_STORE_POSTCODE_FEATURE: 87,
  LANDINGPAGE_HIDE_RESOURCE: 89,
  LANDINGPAGE_HIDE_SERVICES: 90,
  IS_CANCELLATION_FEATURE_ENABLED: 91,
  DELETE_CUSTOMER_DATA_AFTER_THRESHOLD_ENABLED: 92,
  DELETE_CUSTOMER_DATA_LEADTIME: 93,
  LANDINGPAGE_HIDE_LOCATION: 94,
  LANDINGPAGE_REBOOKING_REASON_IS_OPTIONAL: 97,
  LANDINGPAGE_DEACTIVATE_REBOOKING_REASON: 98,
  WIDGET_ASK_FOR_CUSTOMER_NOTIFICATION: 99,
  WIDGET_DISABLE_AUTOMATIC_SEARCH: 101,
  WIDGET_ENABLE_SERVICES_SEARCH: 102,
  LANDINGPAGE_HIDE_PARTNER_CARD: 105,
  WIDGET_HIDE_RESOURCE_STEP_FOR_DEFAULT_WORKERS: 106,
  WIDGET_APPOINTMENT_SERVICE_CATEGORY_STATUS: 107,
  LANDINGPAGE_ALLOW_REBOOKING_FOR_OTHER_WORKERS: 108,
  LEADTIME_APPOINTMENT_REBOOKING: 109,
  WIDGET_DISABLE_SEARCH_ALL_RESOURCE_BUTTON: 110,
  HIDE_INACTIVE_EVENTS: 111,
  CELL_HEIGHT_IN_CALENDAR: 112,
  CALENDAR_FIRST_HOUR: 113,
  CALENDAR_LAST_HOUR: 114,
  ACTIVATE_PHONE_NUMBER_VERIFICATION_FEATURE: 115,
  INTERNAL_APPOINTMENT_LEAD_TIME: 116,
  WIDGET_EMAIL_REQUIRED: 118,
  WIDGET_EMAIL_SHOWN: 119,
  DASHBOARD_CAN_LOGIN_AS_RESELLER_ADMIN: 120,
  UNBLU_USERNAME: 121,
  UNBLU_PASSWORD: 122,
  UNBLU_API_KEY: 123,
  UNBLU_SERVER_URL: 124,
  GOOGLE_API_KEY: 125,
  AUTOMATIC_STORE_ZIP_SEARCH: 126,
  SHOW_DISTANCE_IN_WIDGET: 127,
  LANDINGPAGE_CANCELLATION_REASON_IS_OPTIONAL: 128,
  LANDINGPAGE_DEACTIVATE_CANCELLATION_REASON: 129,
  INTERNAL_WIDGET_EMAIL_REQUIRED: 130,
  INTERNAL_WIDGET_EMAIL_SHOWN: 131,
  INXMAIL_API_URL: 132,
  INXMAIL_API_ID: 133,
  INXMAIL_API_SECRET: 134,
  ENABLE_QRCODE_FEATURE: 135,
  UNBLU_BASE_SERVER_URL: 136,
  UNBLU_AUTH_TYPE: 138,
  WIDGET_SHOW_INLINE_DESCRIPTION: 139,
  SMSAPI_API_KEY: 140,
  OFFICE365_CLIENT_ID: 141,
  OFFICE365_CLIENT_SECRET: 142,
  OFFICE365_TENANT: 143,
  SHOW_DOWNLOAD_SUMMARY_BUTTON_ON_LANDINGPAGE: 144,
  WIDGET_SHOW_PRIVACY_MESSAGE_CHECKBOX: 145,
  UNBLU_REDIRECT_URL: 148,
  IS_ROUND_ROBIN_FEATURE_ENABLED: 149,
  WIDGET_ONLY_RETURN_FIRST_POSSIBLE_APPOINTMENT: 150,
  MAIL_APPOINTMENT_CUSTOMER_BOOKING: 151,
  MAIL_APPOINTMENT_CUSTOMER_MULTIPLE_BOOKING: 152,
  MAIL_APPOINTMENT_CUSTOMER_REMINDER: 153,
  MAIL_APPOINTMENT_CUSTOMER_REBOOK: 154,
  MAIL_APPOINTMENT_CUSTOMER_CANCEL: 155,
  MAIL_APPOINTMENT_CUSTOMER_CANCEL_SMART_ACTION: 156,
  MAIL_APPOINTMENT_CUSTOMER_AFTER_BOOKING_MESSAGE: 157,
  MAIL_APPOINTMENT_CUSTOMER_BEFORE_BOOKING_MESSAGE: 158,
  MAIL_APPOINTMENT_CUSTOMER_SATISFACTION_SURVEY: 159,
  MAIL_EVENT_CUSTOMER_BOOKING: 160,
  MAIL_EVENT_CUSTOMER_MULTIPLE_BOOKING: 161,
  MAIL_EVENT_CUSTOMER_MULTIDAY_BOOKING: 162,
  MAIL_EVENT_CUSTOMER_CANCEL_BY_CUSTOMER: 163,
  MAIL_EVENT_CUSTOMER_CANCEL_BY_PARTNER: 164,
  MAIL_EVENT_CUSTOMER_WAITINGLIST_CANCEL_BY_PARTNER: 165,
  MAIL_EVENT_CUSTOMER_CANCEL_BY_SMARTACTION: 166,
  MAIL_EVENT_CUSTOMER_WAITINGLIST_SWITCH: 167,
  MAIL_EVENT_CUSTOMER_REMINDER: 168,
  MAIL_EVENT_CUSTOMER_AFTER_BOOKING: 169,
  MAIL_EVENT_CUSTOMER_SATISFACTION_SURVEY: 170,
  MAIL_APPOINTMENT_PARTNER_BOOKING: 171,
  MAIL_APPOINTMENT_PARTNER_BOOKING_MULTIPLE: 172,
  MAIL_APPOINTMENT_PARTNER_REBOOK: 173,
  MAIL_APPOINTMENT_PARTNER_CANCEL: 174,
  MAIL_CUSTOMER_PARTNER_LEAD: 175,
  MAIL_EVENT_PARTNER_BOOKING: 176,
  MAIL_EVENT_PARTNER_BOOKING_MULTIDAY: 177,
  MAIL_EVENT_PARTNER_CANCEL: 178,
  MAIL_EVENT_PARTNER_BOOKING_MULTIPLE: 179,
  MAIL_EVENT_PARTNER_WAITINGLIST_SWITCH: 180,
  MAIL_APPOINTMENT_WORKER_BOOKING: 181,
  MAIL_APPOINTMENT_WORKER_BOOKING_MULTIPLE: 182,
  MAIL_EVENT_WORKER_BOOKING: 183,
  MAIL_EVENT_WORKER_BOOKING_MULTIDAY: 184,
  MAIL_EVENT_WORKER_BOOKING_MULTIPLE: 185,
  EVENT_CREATE_CUSTOMER_PARTICIPATION_APPOINTMENT: 187,
  WIDGET_ADDRESS_REQUIRED: 189,
  WIDGET_CUSTOMER_PROFILES_FEATURE_ENABLED: 190,
  WIDGET_SALUTATION_REQUIRED: 191,
  WIDGET_PHONE_REQUIRED: 192,
  WIDGET_MESSAGE_REQUIRED: 193,
  DEFAULT_MAIL_SENDER: 194,
  SENDINBLUE_API_KEY: 195,
  DEFAULT_SMS_PROVIDER: 197,
  SENDINBLUE_SELECTION_ENABLED: 198,
  EXCHANGE_USE_CUSTOM_AUTH: 199,
  ENABLE_AUTOMATIC_WAITINGLIST_REBOOKING: 203,
  ECALL_USERNAME: 204,
  ECALL_PASSWORD: 205,
  SMARTACTION_EXPORT_APPOINTMENTS: 206,
  SMARTACTION_EXPORT_CUSTOMERS: 207,
  SMARTACTION_EXPORT_GROUP_APPOINTMENTS: 208,
  SMARTACTION_EXPORT_INTERVAL: 209,
  SMARTACTION_EXPORT_RECIPIENT: 210,
  DASHBOARD_HIDE_RATING: 214,
  WIDGET_ADOBE_LAUNCH_TRACKING_URL: 217,
  ENABLE_WAITING_TIME_FEATURE: 218,
  DASHBOARD_CUSTOMER_UI_MODE: 219,
  DEACTIVATE_EMAIL_PROVIDER_SELECTION: 220,
  HIDE_CALENDAR_EVENT_TITLE_OF_OTHER_EMPLOYEES: 221,
  HIDE_CONFIGURATION_PROBLEM_BANNER: 222,
  IS_SAFERPAY_ENABLED: 223,
  SAFERPAY_USERNAME: 224,
  SAFERPAY_PASSWORD: 225,
  SAFERPAY_CUSTOMER_ID: 226,
  SAFERPAY_TERMINAL_ID: 227,
  HIDE_BOOK_ADDITIONAL_APPOINTMENT_BUTTON: 229,
  SAFERPAY_API_URL: 230,
  WIDGET_HIDE_EVENT_CAPACITY: 231,
  UNBLU_IDP_ENDPOINT_URL: 232,
  USE_APPOINTMENT_SPECIFIC_CUSTOM_FIELD_VALUES: 233,
  SCROLL_TO_ELEMENT_FEATURE_IS_ENABLED: 234,
  IS_CALDAV_ENABLED: 236,
  WIDGET_INTERNAL_COMMENT_REQUIRED: 237,
  WIDGET_INTERNAL_COMMENT_SHOWN: 238,
  WIDGET_COMPANY_NAME_REQUIRED: 239,
  WIDGET_COMPANY_NAME_SHOWN: 240,
  IS_TEAMS_ENABLED: 241,
  ATTACH_FILES_IN_EXPORT_MAIL: 242,
  IS_STORE_BRANDING_FEATURE_ENABLED: 243,
  SMARTACTION_CUSTOMER_DATA_EXPORT_DURATION: 244,
  SMARTACTION_APPOINTMENT_DATA_EXPORT_DURATION: 245,
  SMARTACTION_GROUP_APPOINTMENT_DATA_EXPORT_DURATION: 246,
  IS_REDIRECT_AFTER_BOOKING_ENABLED: 248,
  SUCCESSFUL_BOOKING_REDIRECT_URL: 249,
  IS_WEBHOOK_ENABLED: 251,
  IS_WIDGET_BANNER_HINT_ENABLED: 252,
  WIDGET_BANNER_HINT_COLOR: 253,
  WIDGET_BANNER_HINT_TEXT: 254,
  IS_APPOINTMENT_STATUS_FEATURE_ENABLED: 255,
  MAIL_APPOINTMENT_STATUS_CUSTOMER_IS_START: 256,
  MAIL_APPOINTMENT_STATUS_CUSTOMER_STATUS_CHANGE: 257,
  MAIL_APPOINTMENT_STATUS_CUSTOMER_IS_CANCELLATION: 258,
  MAIL_APPOINTMENT_STATUS_CUSTOMER_IS_BOOKING_CONFIRMATION: 259,
  MAIL_APPOINTMENT_STATUS_PARTNER_IS_START: 260,
  MAIL_APPOINTMENT_STATUS_PARTNER_STATUS_CHANGE: 261,
  MAIL_APPOINTMENT_STATUS_PARTNER_IS_CANCELLATION: 262,
  MAIL_APPOINTMENT_STATUS_PARTNER_IS_BOOKING_CONFIRMATION: 263,
  IS_BLOCK_OTHER_RESOURCE_FEATURE_ENABLED: 264,
  BLOCK_OTHER_RESOURCE_MODE: 265,
  BOOKING_PAGE_BORDER_RADIUS: 267,
  BOOKING_PAGE_BOX_SHADOW: 268,
  ANYONE_FEATURE_HIDE_RESSOURCE: 269,
  BOOKING_PAGE_PRIMARY_COLOR: 274,
  BOOKING_PAGE_BACKGROUND_COLOR: 275,
  BOOKING_PAGE_HIDE_APPOINTMENT_TAB: 276,
  BOOKING_PAGE_HIDE_EVENT_TAB: 277,
  BOOKING_PAGE_HIDE_EMPLOYEE_TAB: 278,
  BOOKING_PAGE_HIDE_STORE_TAB: 279,
  BOOKING_PAGE_HIDE_ABOUT_US_TAB: 280,
  BOOKING_PAGE_HIDE_RATINGS_TAB: 281,
  DASHBOARD_HIDE_SETUP_ASSISTANT: 282,
  IS_DISPLAY_TIMES_IN_CALENDAR_ENABLED: 283,
  BOOK_ANOTHER_APPOINTMENT_URL: 284,
  WIDGET_TITLE_REQUIRED: 285,
  WIDGET_TITLE_SHOWN: 286,
  WIDGET_COUNTRY_REQUIRED: 287,
  WIDGET_COUNTRY_SHOWN: 288,
  WEBHOOK_STRIP_HTML_TAGS: 289,
  WIDGET_SHOW_RESOURCE_STORE_NAME: 290,
  IS_CAPTCHA_FEATURE_ENABLED: 291,
  NUMBER_OF_RESOURCES_IN_CALENDAR: 292,
  INFORM_EMPLOYEES_ABOUT_CALENDAR_SYNCHRONIZATION_PROBLEMS: 293,
  CALENDAR_SYNCHRONIZATION_PROBLEMS_RECIPIENTS: 294,
  IGNORE_WORKERS_WITHOUT_CALENDAR: 295,
  IS_LEXOFFICE_ENABLED: 296,
  LEXOFFICE_API_KEY: 297,
  BLOCK_ACCESS_FOR_SUPPORTUSER: 298,
  REQUIRED_APPOINTMENT_FORM_FIELDS: 299,
  REQUIRED_CUSTOMER_FORM_FIELDS: 300,
  IS_GROUP_APPOINTMENT_CATEGORY_FEATURE_ENABLED: 301,
  IS_GROUP_APPOINTMENT_STORE_FEATURE_ENABLED: 302,
  SALESFORCE_AUTH_URL: 303,
  SALESFORCE_CLIENT_ID: 304,
  SALESFORCE_CLIENT_SECRET: 305,
  SALESFORCE_USERNAME: 306,
  SALESFORCE_PASSWORD: 307,
  SALESFORCE_API_URL: 308,
  IS_SALESFORCE_ENABLED: 309,
  IS_LDAP_SSO_ENABLED: 310,
  LDAP_HOST: 311,
  LDAP_DN: 312,
  LDAP_PASSWORD: 313,
  LDAP_SEARCH_BASE: 314,
  WIDGET_MOBILE_REQUIRED: 315,
  WIDGET_LANGUAGE_POLISH_HIDDEN: 316,
  SHOW_MOBILE_ON_WIDGET: 317,
  ACTIVATE_MOBILE_NUMBER_VERIFICATION_FEATURE: 318,
  IS_WIDGET_EVENT_SINGLE_SLOT_PRESELECT_ENABLED: 319,
  GROUP_APPOINTMENTS_FILTER_BEHAVIOUR: 320,
  WIDGET_LANGUAGE_SWEDISH_HIDDEN: 321,
  WIDGET_LANGUAGE_SPANISH_HIDDEN: 322,
  SHOW_PHONE_ON_WIDGET: 324,
  IS_EXIVO_ENABLED: 325,
  EXIVO_API_KEY: 326,
  EXIVO_API_SECRET: 327,
  IS_APPOINTMENT_LIMIT_PER_EMPLOYEE_ENABLED: 328,
  APPOINTMENT_LIMIT_PER_EMPLOYEE: 329,
  IS_WIDGET_SERVICE_SHORTCUT_BUTTON_ENABLED: 330,
  AZURE_AD_CLIENT_ID: 331,
  WIDGET_IS_APPOINTMENT_SERVICE_ARROW_ENABLED: 332,
  WHITELISTED_IP_ADDRESSES: 333,
  IS_CALENDLY_ENABLED: 334,
  CALENDLY_CLIENT_ID: 386,
  CALENDLY_CLIENT_SECRET: 387,
  SHOW_POSTCODE_RESULT_AS_STORE_OR_CITIES: 336,
  IS_EVENT_REBOOKING_ENABLED: 337,
  IS_EVENT_REBOOKING_REASON_OPTIONAL: 338,
  IS_EVENT_REBOOKING_REASON_ENABLED: 339,
  IS_EVENT_BOOKING_LIMIT_ENABLED: 342,
  GLOBAL_EVENT_BOOKING_LIMIT: 343,
  MAIL_EVENT_BOOKING_REACHED: 344,
  WIDGET_SHOW_FREE_SLOTS_IN_OVERVIEW: 345,
  BOOKING_PAGE_HIDE_PARTNER_ADDRESS: 346,
  BOOKING_PAGE_UI_MODE: 347,
  BOOKING_PAGE_HIDE_SWITCHER: 348,
  SHOW_MOBILE_OF_WORKER_ON_LANDINGPAGE: 349,
  AZURE_AD_CLIENT_SECRET: 350,
  AZURE_AD_TENANT_ID: 351,
  OFFICE365_CLIENT_SECRET_EXPIRY: 352,
  INXMAIL_API_SECRET_EXPIRY: 353,
  AZURE_AD_CLIENT_SECRET_EXPIRY: 356,
  LOAD_EXTERNAL_CALENDAR_AVAILABILITIES: 357,
  WIDGET_SHOW_LOCATION_ON_APPOINTMENT_PROPOSAL: 359,
  SHOW_WORKER_SELECTION_ON_DATE_STEP: 360,
  IS_AVAILABILITY_AND_ABSENCE_VIEW_IN_CALENDAR_ENABLED: 363,
  SHOW_WIDGET_SLOT_LOCATION: 364,
  WIDGET_REMOVE_DUPLICATE_MINUTES_SLOTS: 365,
  USE_NEW_DASHBOARD_DESIGN: 366,
  SEND_EMAIL_FROM_TEST_SYSTEMS: 369,
  DEFAULT_EMAIL_PROVIDER: 370,
  SMTP_HOST: 371,
  SMTP_PORT: 372,
  SMTP_USERNAME: 373,
  SMTP_PASSWORD: 374,
  SMTP_TLS: 375,
  WIDGET_JUMPS_TO_RESOURCES_STEP_WHEN_SINGLE_SERVICE: 376,
  IS_AUTO_REFRESH_ENABLED: 384,
  AUTO_REFRESH_INTERVAL: 385,
  SPLIT_PERSONAL_FORM_AND_SUMMARY_IN_WIDGET: 388,
  IS_APPOINTMENT_RESERVATION_ENABLED: 389,
  X_MINUTES_APPOINTMENT_RESERVATION: 390,
  SHOW_WORKER_SELECTION_ON_DATE_STEP_ON_LANDING_PAGE: 391,
};

export const MASTER_PARTNER_SETTINGS: MasterPartnerSettingsDao = {
  1: {
    id: 1,
    key: 'custom_email_sender_address',
    default_value: 'info@calenso.com',
    category: 'whitelabel',
    type: 'string',
    requires_verification: 1
  },
  2: {
    id: 2,
    key: 'custom_sms_sender_name',
    default_value: 'CALENSO',
    category: 'whitelabel',
    type: 'string',
    requires_verification: 1
  },
  7: {
    id: 7,
    key: 'landingpage_header_background_color',
    default_value: '#FFFFFF',
    category: 'landingpage',
    type: 'string',
  },
  11: {
    id: 11,
    key: 'landingpage_tab_content',
    default_value: '#FFFFFF',
    category: 'landingpage',
    type: 'string',
  },
  14: {
    id: 14,
    key: 'custom_email_sender_name',
    default_value: 'Calenso',
    category: 'whitelabel',
    type: 'string',
    requires_verification: 1
  },
  17: {
    id: 17,
    key: 'stripe_api_key',
    default_value: null, // pk_live_xxxxxxxxxxxxxxxxxxxxx
    category: 'stripe',
    type: 'string',
  },
  18: {
    id: 18,
    key: 'is_online_payment_mandatory',
    default_value: 0,
    category: 'stripe',
    type: 'boolean',
  },
  19: {
    id: 19,
    key: 'is_service_online_payment_enabled',
    default_value: 0,
    category: 'stripe',
    type: 'boolean',
  },
  20: {
    id: 20,
    key: 'is_event_online_payment_enabled',
    default_value: 0,
    category: 'stripe',
    type: 'boolean',
  },
  34: {
    id: 34,
    key: 'webhook_endpoint',
    default_value: null,
    category: 'webhook',
    type: 'string',
  },
  35: {
    id: 35,
    key: 'webhook_header_key',
    default_value: null, // Authorization
    category: 'webhook',
    type: 'string',
  },
  36: {
    id: 36,
    key: 'webhook_header_value',
    default_value: null, // Bearer ....
    category: 'webhook',
    type: 'string',
  },
  48: {
    id: 48,
    key: 'smartaction_satisfactionsurvey_trigger',
    default_value: 1,
    category: 'smartaction',
    type: 'int',
  },
  49: {
    id: 49,
    key: 'is_bexio_appointment_service_payment_enabled',
    default_value: 0,
    category: 'bexio',
    type: 'boolean',
  },
  50: {
    id: 50,
    key: 'is_bexio_event_payment_enabled',
    default_value: 0,
    category: 'bexio',
    type: 'boolean',
  },
  51: {
    id: 51,
    key: 'bexio_default_tax_id',
    default_value: 16, // UN_77
    category: 'bexio',
    type: 'int',
  },
  52: {
    id: 52,
    key: 'bexio_default_account_id',
    default_value: 55, // Bruttoerlöse Kreditgeschäft
    category: 'bexio',
    type: 'int',
  },
  53: {
    id: 53,
    key: 'bexio_default_bank_account_id',
    default_value: 1, // Example
    category: 'bexio',
    type: 'int',
  },
  55: {
    id: 55,
    key: 'bexio_only_sync_customer',
    default_value: 0, // Example
    category: 'bexio',
    type: 'int',
  },
  56: {
    id: 56,
    key: 'online_payment_show_on_site_payment_type',
    default_value: 1, // Example
    category: 'online_payment',
    type: 'int',
  },
  61: {
    id: 61,
    key: 'is_appointment_rebooking_disabled',
    default_value: '0',
    category: 'appointment',
    type: 'boolean',
  },
  62: {
    id: 62,
    key: 'bexio_mwst_type',
    default_value: '0',
    category: 'bexio',
    type: 'int',
  },
  63: {
    id: 63,
    key: 'custom_email_for_cancellations',
    default_value: '',
    category: 'notification',
    type: 'string',
  },
  64: {
    id: 64,
    key: 'dashboard_hide_progress_bar',
    default_value: '0',
    category: 'dashboard',
    type: 'boolean',
  },
  65: {
    id: 65,
    key: 'dashboard_hide_upcoming_bookings',
    default_value: '0',
    category: 'dashboard',
    type: 'boolean',
  },
  66: {
    id: 66,
    key: 'dashboard_hide_statistics',
    default_value: '0',
    category: 'dashboard',
    type: 'boolean',
  },
  67: {
    id: 67,
    key: 'dashboard_hide_booking_chart',
    default_value: '0',
    category: 'dashboard',
    type: 'boolean',
  },
  68: {
    id: 68,
    key: 'dashboard_hide_shortcuts',
    default_value: '0',
    category: 'dashboard',
    type: 'boolean',
  },
  69: {
    id: 69,
    key: 'dashboard_hide_coming_soon',
    default_value: '0',
    category: 'dashboard',
    type: 'boolean',
  },
  70: {
    id: 70,
    key: 'dashboard_hide_booking_link',
    default_value: '0',
    category: 'dashboard',
    type: 'boolean',
  },
  72: {
    id: 72,
    key: 'show_stores_as_dropdown',
    default_value: '0',
    category: 'widget',
    type: 'boolean'
  },
  74: {
    id: 74,
    key: 'only_create_appointment_when_event_registration_exists',
    default_value: '0',
    category: 'events',
    type: 'boolean',
  },
  75: {
    id: 75,
    key: 'bexio_mwst_is_net',
    default_value: '0',
    category: 'bexio',
    type: 'boolean',
  },
  76: {
    id: 76,
    key: 'bexio_contact_person_id',
    default_value: '0',
    category: 'bexio',
    type: 'int',
  },
  86: {
    id: 86,
    key: 'offer_delete_customer_data_option',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  87: {
    id: 87,
    key: 'enable_store_postcode_feature',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  89: {
    id: 89,
    key: 'landingpage_hide_resource',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  90: {
    id: 90,
    key: 'landingpage_hide_services',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  91: {
    id: 91,
    key: 'is_cancellation_feature_enabled',
    default_value: 1,
    category: 'setting',
    type: 'boolean',
  },
  92: {
    id: 92,
    key: 'delete_customer_data_after_threshold_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  93: {
    id: 93,
    key: 'delete_customer_data_leadtime', // in days
    default_value: 30,
    category: 'setting',
    type: 'int',
  },
  94: {
    id: 94,
    key: 'landingpage_hide_location',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  97: {
    id: 97,
    key: 'landingpage_rebooking_reason_is_optional',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  98: {
    id: 98,
    key: 'landingpage_deactivate_rebooking_reason',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  99: {
    id: 99,
    key: 'widget_ask_for_customer_notification',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  101: {
    id: 101,
    key: 'widget_disable_automatic_search',
    default_value: 0,
    category: 'widget',
    type: 'boolean',
  },
  102: {
    id: 102,
    key: 'widget_enable_services_search',
    default_value: 0,
    category: 'widget',
    type: 'boolean',
  },
  105: {
    id: 105,
    key: 'landingpage_hide_partner_card',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  106: {
    id: 106,
    key: 'widget_hide_resource_step_for_default_workers',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  // 0: all collapsed
  // 1: first open
  // 2: all open
  107: {
    id: 107,
    key: 'widget_appointment_service_category_status',
    default_value: 1,
    category: 'setting',
    type: 'int',
  },
  108: {
    id: 108,
    key: 'landingpage_allow_rebooking_for_other_workers',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  109: {
    id: 109,
    key: 'leadtime_appointment_rebooking',
    default_value: 24,
    category: 'setting',
    type: 'int',
  },
  110: {
    id: 110,
    key: 'widget_disable_search_all_resource_button',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  111: {
    id: 111,
    key: 'hide_inactive_events',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  112: {
    id: 112,
    key: 'cell_height_in_calendar',
    default_value: 100,
    category: 'setting',
    type: 'int',
  },
  113: {
    id: 113,
    key: 'calendar_first_hour',
    default_value: 6,
    category: 'setting',
    type: 'int',
  },
  114: {
    id: 114,
    key: 'calendar_last_hour',
    default_value: 22,
    category: 'setting',
    type: 'int',
  },
  115: {
    id: 115,
    key: 'activate_phone_number_verification_feature',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  116: {
    id: 116,
    key: 'internal_appointment_lead_time',
    default_value: 24,
    category: 'setting',
    type: 'int',
  },
  118: {
    id: 118,
    key: 'widget_email_required',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  119: {
    id: 119,
    key: 'widget_email_shown',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  120: {
    id: 120,
    key: 'dashboard_can_login_as_reseller_admin',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  121: {
    id: 121,
    key: 'unblu_username',
    default_value: null,
    category: 'setting',
    type: 'int'
  },
  122: {
    id: 122,
    key: 'unblu_password',
    default_value: null,
    category: 'setting',
    type: 'int'
  },
  123: {
    id: 123,
    key: 'unblu_api_key',
    default_value: null,
    category: 'setting',
    type: 'int'
  },
  124: {
    id: 124,
    key: 'unblu_server_url',
    default_value: null,
    category: 'setting',
    type: 'int'
  },
  125: {
    id: 125,
    key: 'google_api_key',
    default_value: null,
    category: 'setting',
    type: 'int'
  },
  126: {
    id: 126,
    key: 'automatic_store_zip_search',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  127: {
    id: 127,
    key: 'show_distance_in_widget',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  128: {
    id: 128,
    key: 'landingpage_cancellation_reason_is_optional',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  129: {
    id: 129,
    key: 'landingpage_deactivate_cancellation_reason',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  130: {
    id: 130,
    key: 'internal_widget_email_required',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  131: {
    id: 131,
    key: 'internal_widget_email_shown',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  132: {
    id: 132,
    key: 'inxmail_api_url',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  133: {
    id: 133,
    key: 'inxmail_api_id',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  134: {
    id: 134,
    key: 'inxmail_api_secret',
    default_value: null,
    category: 'setting',
    type: 'int'
  },
  135: {
    id: 135,
    key: 'enable_qrcode_feature',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  136: {
    id: 136,
    key: 'unblu_base_server_url', // https://unblu.cloud
    default_value: null,
    category: 'setting',
    type: 'int'
  },
  138: {
    id: 138,
    key: 'unblu_auth_type',
    default_value: 'basic',
    category: 'setting',
    type: 'string'
  },
  139: {
    id: 139,
    key: 'widget_show_inline_description',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  140: {
    id: 140,
    key: 'smsapi_api_key',
    default_value: null,
    category: 'setting',
    type: 'string',
    requires_verification: 1
  },
  141: {
    id: 141,
    key: 'office365_client_id',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  142: {
    id: 142,
    key: 'office365_client_secret',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  143: {
    id: 143,
    key: 'office365_tenant',
    default_value: 'common',
    category: 'setting',
    type: 'string'
  },
  144: {
    id: 144,
    key: 'show_download_summary_button_on_landingpage',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  145: {
    id: 145,
    key: 'widget_show_privacy_message_checkbox',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  148: {
    id: 148,
    key: 'unblu_redirect_url',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  149: {
    id: 149,
    key: 'is_round_robin_feature_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  150: {
    id: 150,
    key: 'widget_only_return_first_possible_appointment',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  // mail send settings
  151: {
    id: 151,
    key: 'mail_appointment_customer_booking',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  152: {
    id: 152,
    key: 'mail_appointment_customer_multiple_booking',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  153: {
    id: 153,
    key: 'mail_appointment_customer_reminder',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  154: {
    id: 154,
    key: 'mail_appointment_customer_rebook',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  155: {
    id: 155,
    key: 'mail_appointment_customer_cancel',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  156: {
    id: 156,
    key: 'mail_appointment_customer_cancel_smart_action',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  157: {
    id: 157,
    key: 'mail_appointment_customer_after_booking_message',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  158: {
    id: 158,
    key: 'mail_appointment_customer_before_booking_message',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  159: {
    id: 159,
    key: 'mail_appointment_customer_satisfaction_survey',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  160: {
    id: 160,
    key: 'mail_event_customer_booking',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  161: {
    id: 161,
    key: 'mail_event_customer_multiple_booking',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  162: {
    id: 162,
    key: 'mail_event_customer_multiday_booking',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  163: {
    id: 163,
    key: 'mail_event_customer_cancel_by_customer',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  164: {
    id: 164,
    key: 'mail_event_customer_cancel_by_partner',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  165: {
    id: 165,
    key: 'mail_event_customer_waitinglist_cancel_by_partner',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  166: {
    id: 166,
    key: 'mail_event_customer_cancel_by_smartaction',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  167: {
    id: 167,
    key: 'mail_event_customer_waitinglist_switch',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  168: {
    id: 168,
    key: 'mail_event_customer_reminder',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  169: {
    id: 169,
    key: 'mail_event_customer_after_booking',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  170: {
    id: 170,
    key: 'mail_event_customer_satisfaction_survey',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  // partner
  171: {
    id: 171,
    key: 'mail_appointment_partner_booking',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  172: {
    id: 172,
    key: 'mail_appointment_partner_booking_multiple',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  173: {
    id: 173,
    key: 'mail_appointment_partner_rebook',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  174: {
    id: 174,
    key: 'mail_appointment_partner_cancel',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  175: {
    id: 175,
    key: 'mail_customer_partner_lead',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  176: {
    id: 176,
    key: 'mail_event_partner_booking',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  177: {
    id: 177,
    key: 'mail_event_partner_booking_multiday',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  178: {
    id: 178,
    key: 'mail_event_partner_cancel',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  179: {
    id: 179,
    key: 'mail_event_partner_booking_multiple',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  180: {
    id: 180,
    key: 'mail_event_partner_waitinglist_switch',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  // worker
  181: {
    id: 181,
    key: 'mail_appointment_worker_booking',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  182: {
    id: 182,
    key: 'mail_appointment_worker_booking_multiple',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  183: {
    id: 183,
    key: 'mail_event_worker_booking',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  184: {
    id: 184,
    key: 'mail_event_worker_booking_multiday',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  185: {
    id: 185,
    key: 'mail_event_worker_booking_multiple',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  187: {
    id: 187,
    key: 'event_create_customer_participation_appointment',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  189: {
    id: 189,
    key: 'widget_address_required',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  190: {
    id: 190,
    key: 'widget_customer_profiles_feature_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  191: {
    id: 191,
    key: 'widget_salutation_required',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  192: {
    id: 192,
    key: 'widget_phone_required',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  193: {
    id: 193,
    key: 'widget_message_required',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  194: {
    id: 194,
    key: 'default_mail_sender',
    default_value: 'sendgrid', // sendgrid, Brevo, mailtrap
    category: 'setting',
    type: 'string',
    requires_verification: 1
  },
  195: {
    id: 195,
    key: 'sendinblue_api_key',
    default_value: null,
    category: 'setting',
    type: 'string',
    requires_verification: 1
  },
  197: {
    id: 197,
    key: 'default_sms_provider',
    default_value: 'smsapi',
    category: 'setting',
    type: 'string',
    requires_verification: 1
  },
  198: {
    id: 198,
    key: 'sendinblue_selection_enabled',
    default_value: '0',
    category: 'setting',
    type: 'int'
  },
  // sanitas certificate request per exchange
  199: {
    id: 199,
    key: 'exchange_use_custom_auth',
    default_value: '0',
    category: 'setting',
    type: 'int'
  },
  203: {
    id: 203,
    key: 'enable_automatic_waitinglist_rebooking',
    default_value: '1',
    category: 'setting',
    type: 'int'
  },
  204: {
    id: 204,
    key: 'ecall_username',
    default_value: null,
    category: 'setting',
    type: 'string',
    requires_verification: 1
  },
  205: {
    id: 205,
    key: 'ecall_password',
    default_value: null,
    category: 'setting',
    type: 'string',
    requires_verification: 1
  },
  // smartaction export
  206: {
    id: 206,
    key: 'smartaction_export_appointments',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  207: {
    id: 207,
    key: 'smartaction_export_customers',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  208: {
    id: 208,
    key: 'smartaction_export_group_appointments',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  209: {
    id: 209,
    key: 'smartaction_export_interval',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  210: {
    id: 210,
    key: 'smartaction_export_recipient',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  214: {
    id: 214,
    key: 'dashboard_hide_rating',
    default_value: '0',
    category: 'dashboard',
    type: 'boolean',
  },
  217: {
    id: 217,
    key: 'widget_adobe_launch_tracking_url',
    default_value: null,
    category: 'analytics-integration',
    type: 'string',
  },
  218: {
    id: 218,
    key: 'enable_waiting_time_feature',
    default_value: '0',
    category: 'dashboard',
    type: 'boolean',
  },
  219: {
    id: 219,
    key: 'dashboard_customer_ui_mode',
    default_value: 'list',
    category: 'dashboard',
    type: 'string',
  },
  220: {
    id: 220,
    key: 'deactivate_email_provider_selection',
    default_value: 0,
    category: 'dashboard',
    type: 'boolean',
  },
  221: {
    id: 221,
    key: 'hide_calendar_event_title_of_other_employees',
    default_value: 0,
    category: 'dashboard',
    type: 'boolean',
  },
  222: {
    id: 222,
    key: 'hide_configuration_problem_banner',
    default_value: 0,
    category: 'dashboard',
    type: 'boolean',
  },
  // saferpay
  223: {
    id: 223,
    key: 'is_saferpay_enabled',
    default_value: 0,
    category: 'payment',
    type: 'boolean',
  },
  224: {
    id: 224,
    key: 'saferpay_username',
    default_value: null,
    category: 'payment',
    type: 'string',
  },
  225: {
    id: 225,
    key: 'saferpay_password',
    default_value: null,
    category: 'payment',
    type: 'string',
  },
  226: {
    id: 226,
    key: 'saferpay_customer_id',
    default_value: null,
    category: 'payment',
    type: 'string',
  },
  227: {
    id: 227,
    key: 'saferpay_terminal_id',
    default_value: null,
    category: 'payment',
    type: 'string',
  },
  229: {
    id: 229,
    key: 'hide_book_additional_appointment_button',
    default_value: 0,
    category: 'widget',
    type: 'int',
  },
  230: {
    id: 230,
    key: 'saferpay_api_url',
    default_value: 'https://test.saferpay.com/api',
    category: 'payment',
    type: 'string',
  },
  231: {
    id: 231,
    key: 'widget_hide_event_capacity',
    default_value: 0,
    category: 'widget',
    type: 'int',
  },
  232: {
    id: 232,
    key: 'unblu_idp_endpoint_url',
    default_value: null,
    category: 'setting',
    type: 'string',
  },
  233: {
    id: 233,
    key: 'use_appointment_specific_custom_field_values',
    default_value: 1,
    category: 'setting',
    type: 'int',
  },
  234: {
    id: 234,
    key: 'scroll_to_element_feature_is_enabled',
    default_value: 1,
    category: 'setting',
    type: 'boolean',
  },
  236: {
    id: 236,
    key: 'is_caldav_enabled',
    default_value: '1',
    category: 'dashboard',
    type: 'boolean',
  },
  237: {
    id: 237,
    key: 'widget_internal_comment_required',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  238: {
    id: 238,
    key: 'widget_internal_comment_shown',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  239: {
    id: 239,
    key: 'widget_company_name_required',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  240: {
    id: 240,
    key: 'widget_company_name_shown',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  241: {
    id: 241,
    key: 'is_teams_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  242: {
    id: 242,
    key: 'attach_files_in_export_mail',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  243: {
    id: 243,
    key: 'is_store_branding_feature_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  244: {
    id: 244,
    key: 'smartaction_customer_data_export_duration',
    default_value: 0,
    category: 'setting',
    type: 'int'
  },
  245: {
    id: 245,
    key: 'smartaction_appointment_data_export_duration',
    default_value: 0,
    category: 'setting',
    type: 'int'
  },
  246: {
    id: 246,
    key: 'smartaction_group_appointment_data_export_duration',
    default_value: 0,
    category: 'setting',
    type: 'int'
  },
  248: {
    id: 248,
    key: 'is_redirect_after_booking_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  249: {
    id: 249,
    key: 'successful_booking_redirect_url',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  251: {
    id: 251,
    key: 'is_webhook_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  252: {
    id: 252,
    key: 'is_widget_banner_hint_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  253: {
    id: 253,
    key: 'widget_banner_hint_color',
    default_value: 'danger',
    category: 'setting',
    type: 'string'
  },
  254: {
    id: 254,
    key: 'widget_banner_hint_text',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  255: {
    id: 255,
    key: 'is_appointment_status_feature_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  256: {
    id: 256,
    key: 'mail_appointment_status_customer_is_start',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  257: {
    id: 257,
    key: 'mail_appointment_status_customer_status_change',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  258: {
    id: 258,
    key: 'mail_appointment_status_customer_is_cancellation',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  259: {
    id: 259,
    key: 'mail_appointment_status_customer_is_booking_confirmation',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  260: {
    id: 260,
    key: 'mail_appointment_status_partner_is_start',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  261: {
    id: 261,
    key: 'mail_appointment_status_partner_status_change',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  262: {
    id: 262,
    key: 'mail_appointment_status_partner_is_cancellation',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  263: {
    id: 263,
    key: 'mail_appointment_status_partner_is_booking_confirmation',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  264: {
    id: 264,
    key: 'is_block_other_resource_feature_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  265: {
    id: 265,
    key: 'block_other_resource_mode',
    default_value: 2,
    category: 'setting',
    type: 'int'
  },
  267: {
    id: 267,
    key: 'booking_page_border_radius',
    default_value: '0px',
    category: 'setting',
    type: 'string'
  },
  268: {
    id: 268,
    key: 'booking_page_box_shadow',
    default_value: 'none',
    category: 'setting',
    type: 'string'
  },
  269: {
    id: 269,
    key: 'anyone_feature_hide_ressource',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  274: {
    id: 274,
    key: 'booking_page_primary_color',
    default_value: '#000000',
    category: 'setting',
    type: 'string'
  },
  275: {
    id: 275,
    key: 'booking_page_background_color',
    default_value: '#EBF1FB',
    category: 'setting',
    type: 'string'
  },
  276: {
    id: 276,
    key: 'booking_page_hide_appointment_tab',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  277: {
    id: 277,
    key: 'booking_page_hide_event_tab',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  278: {
    id: 278,
    key: 'booking_page_hide_employee_tab',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  279: {
    id: 279,
    key: 'booking_page_hide_store_tab',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  280: {
    id: 280,
    key: 'booking_page_hide_about_us_tab',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  281: {
    id: 281,
    key: 'booking_page_hide_ratings_tab',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  282: {
    id: 282,
    key: 'dashboard_hide_setup_assistant',
    default_value: '0',
    category: 'dashboard',
    type: 'boolean',
  },
  283: {
    id: 283,
    key: 'is_display_times_in_calendar_enabled',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  284: {
    id: 284,
    key: 'book_another_appointment_url',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  285: {
    id: 285,
    key: 'widget_title_required',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  286: {
    id: 286,
    key: 'widget_title_shown',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  287: {
    id: 287,
    key: 'widget_country_required',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  288: {
    id: 288,
    key: 'widget_country_shown',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  289: {
    id: 289,
    key: 'webhook_strip_html_tags',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  290: {
    id: 290,
    key: 'widget_show_resource_store_name',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  291: {
    id: 291,
    key: 'is_captcha_feature_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  292: {
    id: 292,
    key: 'number_of_resources_in_calendar',
    default_value: 5,
    category: 'setting',
    type: 'int'
  },
  293: {
    id: 293,
    key: 'inform_employees_about_calendar_synchronization_problems',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  294: {
    id: 294,
    key: 'calendar_synchronization_problems_recipients',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  295: {
    id: 295,
    key: 'ignore_workers_without_calendar',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  296: {
    id: 296,
    key: 'is_lexoffice_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  297: {
    id: 297,
    key: 'lexoffice_api_key',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  298: {
    id: 298,
    key: 'block_access_for_supportuser',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  299: {
    id: 299,
    key: 'required_appointment_form_fields',
    default_value: 'resource,start,end,cancellation',
    category: 'setting',
    type: 'string'
  },
  300: {
    id: 300,
    key: 'required_customer_form_fields',
    default_value: 'prename,lastname,resource,notification_type,customer_type',
    category: 'setting',
    type: 'string'
  },
  301: {
    id: 301,
    key: 'is_group_appointment_category_feature_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  302: {
    id: 302,
    key: 'is_group_appointment_store_feature_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  303: {
    id: 303,
    key: 'salesforce_auth_url',
    default_value: null,
    category: 'setting',
    type: 'string'

  },
  304: {
    id: 304,
    key: 'salesforce_client_id',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  305: {
    id: 305,
    key: 'salesforce_client_secret',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  306: {
    id: 306,
    key: 'salesforce_username',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  307: {
    id: 307,
    key: 'salesforce_password',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  308: {
    id: 308,
    key: 'salesforce_api_url',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  309: {
    id: 309,
    key: 'is_salesforce_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  310: {
    id: 310,
    key: 'is_ldap_sso_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  311: {
    id: 311,
    key: 'ldap_host',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  312: {
    id: 312,
    key: 'ldap_dn',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  313: {
    id: 313,
    key: 'ldap_password',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  314: {
    id: 314,
    key: 'ldap_search_base',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  315: {
    id: 315,
    key: 'widget_mobile_required',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  316: {
    id: 316,
    key: 'widget_language_polish_hidden',
    default_value: 1,
    category: 'widget',
    type: 'boolean',
  },
  317: {
    id: 317,
    key: 'show_mobile_on_widget',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  318: {
    id: 318,
    key: 'activate_mobile_number_verification_feature',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  319: {
    id: 319,
    key: 'is_widget_event_single_slot_preselect_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  320: {
    id: 320,
    key: 'group_appointments_filter_behaviour',
    default_value: 'or',
    category: 'setting',
    type: 'setting',
  },
  321: {
    id: 321,
    key: 'widget_language_swedish_hidden',
    default_value: 1,
    category: 'widget',
    type: 'boolean',
  },
  322: {
    id: 322,
    key: 'widget_language_spanish_hidden',
    default_value: '1',
    category: 'widget',
    type: 'boolean',
  },
  324: {
    id: 324,
    key: 'show_phone_on_widget',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  325: {
    id: 325,
    key: 'is_exivo_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  326: {
    id: 326,
    key: 'exivo_api_key',
    default_value: null,
    category: 'setting',
    type: 'string',
  },
  327: {
    id: 327,
    key: 'exivo_api_secret',
    default_value: null,
    category: 'setting',
    type: 'string',
  },
  328: {
    id: 328,
    key: 'is_appointment_limit_per_employee_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  329: {
    id: 329,
    key: 'appointment_limit_per_employee',
    default_value: null,
    category: 'setting',
    type: 'string',
  },
  330: {
    id: 330,
    key: 'is_widget_service_shortcut_button_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  331: {
    id: 331,
    key: 'azure_ad_client_id',
    default_value: null,
    category: 'setting',
    type: 'string',
  },
  332: {
    id: 332,
    key: 'widget_is_appointment_service_arrow_enabled',
    default_value: '0',
    category: 'widget',
    type: 'boolean',
  },
  333: {
    id: 333,
    key: 'whitelisted_ip_addresses',
    default_value: null,
    category: 'setting',
    type: 'string',
  },
  334: {
    id: 334,
    key: 'is_calendly_enabled',
    default_value: '0',
    category: 'setting',
    type: 'boolean',
  },
  386: {
    id: 386,
    key: 'calendly_client_id',
    default_value: null,
    category: 'setting',
    type: 'string',
  },
  387: {
    id: 387,
    key: 'calendly_client_secret',
    default_value: null,
    category: 'setting',
    type: 'string',
  },
  336: {
    id: 336,
    key: 'include_city_name_in_postcode_results',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  337: {
    id: 337,
    key: 'is_event_rebooking_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  338: {
    id: 338,
    key: 'is_event_rebooking_reason_optional',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  339: {
    id: 339,
    key: 'is_event_rebooking_reason_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  342: {
    id: 342,
    key: 'is_event_booking_limit_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean',
  },
  343: {
    id: 343,
    key: 'global_event_booking_limit',
    default_value: 2,
    category: 'setting',
    type: 'string',
  },
  344: {
    id: 344,
    key: 'mail_event_booking_reached',
    default_value: 1,
    category: 'setting',
    type: 'boolean'
  },
  345: {
    id: 345,
    key: 'widget_show_free_slots_in_overview',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  346: {
    id: 346,
    key: 'booking_page_hide_partner_address',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  347: {
    id: 347,
    key: 'booking_page_ui_mode',
    default_value: 'grid',
    category: 'setting',
    type: 'string'
  },
  348: {
    id: 348,
    key: 'booking_page_hide_switcher',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  349: {
    id: 349,
    key: 'show_mobile_of_worker_on_landingpage',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  350: {
    id: 350,
    key: 'azure_ad_client_secret',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  351: {
    id: 351,
    key: 'azure_ad_tenant_id',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  352: {
    id: 352,
    key: 'office365_client_secret_expiry',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  353: {
    id: 353,
    key: 'inxmail_api_secret_expiry',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  356: {
    id: 356,
    key: 'azure_ad_client_secret_expiry',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  357: {
    id: 357,
    key: 'load_external_calendar_availabilities',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  359: {
    id: 359,
    key: 'widget_show_location_on_appointment_proposal',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  360: {
    id: 360,
    key: 'show_worker_selection_on_date_step',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  363: {
    id: 363,
    key: 'is_availability_and_absence_view_in_calendar_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  364: {
    id: 364,
    key: 'show_widget_slot_location',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  365: {
    id: 365,
    key: 'widget_remove_duplicate_minutes_slots',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  366: {
    id: 366,
    key: 'use_new_dashboard_design',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  369: {
    id: 369,
    key: 'send_email_from_test_systems',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  370: {
    id: 370,
    key: 'default_email_provider',
    default_value: 'brevo',
    category: 'setting',
    type: 'string'
  },
  371: {
    id: 371,
    key: 'smtp_host',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  372: {
    id: 372,
    key: 'smtp_port',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  373: {
    id: 373,
    key: 'smtp_username',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  374: {
    id: 374,
    key: 'smtp_password',
    default_value: null,
    category: 'setting',
    type: 'string'
  },
  375: {
    id: 375,
    key: 'smtp_tls',
    default_value: false,
    category: 'setting',
    type: 'boolean'
  },
  376: {
    id: 376,
    key: 'widget_jumps_to_resources_step_when_single_service',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  384: {
    id: 384,
    key: 'is_auto_refresh_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  385: {
    id: 385,
    key: 'auto_refresh_interval',
    default_value: 5,
    category: 'setting',
    type: 'int'
  },
  388: {
    id: 388,
    key: 'split_personal_form_and_summary_in_widget',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  389: {
    id: 389,
    key: 'is_appointment_reservation_enabled',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
  390: {
    id: 390,
    key: 'x_minutes_appointment_reservation',
    default_value: 15,
    category: 'setting',
    type: 'int'
  },
  391: {
    id: 391,
    key: 'show_worker_selection_on_date_step_on_landing_page',
    default_value: 0,
    category: 'setting',
    type: 'boolean'
  },
};

export const RESELLER_WHITELIST_PATH = ['appointments', 'events', 'customers', 'settings'];
